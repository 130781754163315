//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import EmptyCategoryCollectionCard from "@/components/common/EmptyCategoryCollectionCard";

export default {
  name: "EmptyCategoryCollectionSlider",
  components: {
    EmptyCategoryCollectionCard
  },
  props: {
    gap: {
      type: Number,
      default: () => 32
    },
    columns: {
      type: Number,
      default: () => 4
    }
  },
  computed: {
    ...mapGetters({
      width: 'viewport/width',
    }),
    style() {
      return {
        'grid-template-columns': `repeat(${this.columns}, 1fr)`,
        'grid-gap': `${this.gap}px`,
      }
    }
  },
  watch: {
    width() {
      this.$emit('set-wrapper-width', this.$refs['empty-category-collection-slider-wrapper']?.clientWidth)
      this.$emit('set-item-width', this.$refs['empty-category-collection-slider-item'][0]?.$el?.clientWidth)
    }
  },
  mounted() {
    this.$emit('set-wrapper-width', this.$refs['empty-category-collection-slider-wrapper']?.clientWidth)
    this.$emit('set-item-width', this.$refs['empty-category-collection-slider-item'][0]?.$el?.clientWidth)
  }
}
