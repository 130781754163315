//
//
//
//
//
//

export default {
  name: "IntersectionComponent",
  props: {
    once: {
      type: Boolean,
      default: () => true,
    },
    margin: {
      type: String,
      default: () => '0px 0px -200px'
    },
  },
  data() {
    return {
      observer: null
    }
  },
  created() {
    if (!process.server) {
      this.observer = new IntersectionObserver(
        this.callback,
        {
          root: this.$el,
          rootMargin: this.margin,
        }
      );
    }
  },
  mounted() {
    if (this.observer) {
      this.observer.observe(this.$el);
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    callback(entries) {
      entries.forEach(item => {
        if (item.isIntersecting) {
          return
        }
        this.observer.unobserve(item.target);
        this.$emit('intersected', true)
      });
    }
  }
}
