//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {parser} from "@/utils/parser";

export default {
  name: "SectionRecommendations",
  components: {
    IntersectionComponent: () => import("@/components/common/IntersectionComponent/IntersectionComponent"),
    EmptyCategoryCollectionSlider: () => import("@/components/common/EmptyCategoryCollectionSlider"),
    Section: () => import("@/components/common/Section/index"),
    Scroller: () => import("@/components/common/Scroller/index"),
    RecommendationCard: () => import("@/components/common/RecommendationCard/index"),
  },
  props: {
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    },
    query: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      promo: [],
      show: true,
      pending: true,
      showContentAfterIntersection: false,
    };
  },
  computed: {
    defaultQuery() {
      return {
        resolution: "micro",
        lang: this.$i18n.locale
      };
    },
    queryParams() {
      return {
        ...this.defaultQuery,
        ...this.query
      };
    },
    filteredArticles() {
      return this?.promo?.length > 7 ?
        this?.promo?.filter((item, index) => index < 8) :
        this?.promo?.filter((item, index) => index < 4);
    },
    gap() {
      return this.$mediaSwitch({hd: 32, sm: 16, defaultValue: 24});
    },
    columns() {
      return this.$mediaSwitch({hd: 4, md: 2, sm: 1, defaultValue: 3});
    }
  },
  methods: {
    intersected() {
      this.showContentAfterIntersection = true;
      this.$axios.$get(`/api/journals?promo${parser({
        params: this.queryParams
      })}`)
        .then(response => {
          this.promo = response.data || [];
        })
        .catch(() => null)
        .finally(() => {
          this.pending = false;
          this.show = this.promo.length > 3;
        });
    },
  }
};
